import { createSlice, current } from "@reduxjs/toolkit";

export const KEY = "user";

const defaultValue = {
  allLimitOverLoaded: false,
  campaignsLimitOverLoaded: false,
  countActivity: {},
  documentsLimitOverLoaded: false,
  getUserData: false,
  mailsLimitOverLoaded: false,
  maxCompletedCampaigns: 0,
  notContactList: false,
  notSubscription: false,
  onlyPlan: false,
  periodEnd: false,
  profile: {},
  specialCollaboration: false,
  studentSubscription: false,
  subscription: {},
  trialExpired: false,
  unreadNotifications: 0,
  user: {},
};

const authUserInfo = createSlice({
  name: KEY,
  initialState: defaultValue,
  reducers: {
    clearAuthUser: () => {
      return defaultValue;
    },
    setAuthUser: (state, action) => {
      return action.payload;
    },
    updateAuthUser: (state, action) => {
      const currentState = { ...current(state) };
      const newState = {
        ...currentState,
        ...action.payload,
        countActivity: {
          ...currentState.countActivity,
          ...action.payload.countActivity,
        },
        onlyPlan: {
          ...currentState.onlyPlan,
          ...action.payload.onlyPlan,
        },
        profile: {
          ...currentState.profile,
          ...action.payload.profile,
        },
        subscription: {
          ...currentState.subscription,
          ...action.payload.subscription,
        },
        user: {
          ...currentState.user,
          ...action.payload.user,
        },
      };

      return newState;
    },
    removeSubscription: (state, action) => {
      state.profile.subscriptionIsActive = false;
      state.profile.type = "";
      state.profile.interval = "";
    },
  },
});

export const { setAuthUser, updateAuthUser, removeSubscription, clearAuthUser } = authUserInfo.actions;

export const getAuthUser = (state) => state[KEY];

export default authUserInfo.reducer;
