import { createSlice } from "@reduxjs/toolkit";

export const KEY = "typeOfList";

const typeOfListSlice = createSlice({
  name: KEY,
  initialState: { value: "" },
  reducers: {
    setTypeOfList: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const currentTypeOfList = (state) => state[KEY];
export const { setTypeOfList } = typeOfListSlice.actions;
export default typeOfListSlice.reducer;
