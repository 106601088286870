import styled from "styled-components";
/*eslint-disable*/
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.generalColor.popupBackground};
  z-index: 10;
  @media(max-width: 767px) {
    min-height: 100vh;
    height: 100%;
  };
`;

const Loader = styled.div`
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #65abf8, 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.5), -1.8em -1.8em 0 0em rgba(101,171,248, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.7), 1.8em -1.8em 0 0em #65abf8, 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.5), 1.8em -1.8em 0 0em rgba(101,171,248, 0.7), 2.5em 0em 0 0em #65abf8, 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.5), 2.5em 0em 0 0em rgba(101,171,248, 0.7), 1.75em 1.75em 0 0em #65abf8, 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.5), 1.75em 1.75em 0 0em rgba(101,171,248, 0.7), 0em 2.5em 0 0em #65abf8, -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.5), 0em 2.5em 0 0em rgba(101,171,248, 0.7), -1.8em 1.8em 0 0em #65abf8, -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.5), -1.8em 1.8em 0 0em rgba(101,171,248, 0.7), -2.6em 0em 0 0em #65abf8, -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.5), -2.6em 0em 0 0em rgba(101,171,248, 0.7), -1.8em -1.8em 0 0em #65abf8;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #65abf8, 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.5), -1.8em -1.8em 0 0em rgba(101,171,248, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.7), 1.8em -1.8em 0 0em #65abf8, 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.5), 1.8em -1.8em 0 0em rgba(101,171,248, 0.7), 2.5em 0em 0 0em #65abf8, 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.5), 2.5em 0em 0 0em rgba(101,171,248, 0.7), 1.75em 1.75em 0 0em #65abf8, 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.5), 1.75em 1.75em 0 0em rgba(101,171,248, 0.7), 0em 2.5em 0 0em #65abf8, -1.8em 1.8em 0 0em rgba(101,171,248, 0.2), -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.5), 0em 2.5em 0 0em rgba(101,171,248, 0.7), -1.8em 1.8em 0 0em #65abf8, -2.6em 0em 0 0em rgba(101,171,248, 0.2), -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.5), -1.8em 1.8em 0 0em rgba(101,171,248, 0.7), -2.6em 0em 0 0em #65abf8, -1.8em -1.8em 0 0em rgba(101,171,248, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(101,171,248, 0.2), 1.8em -1.8em 0 0em rgba(101,171,248, 0.2), 2.5em 0em 0 0em rgba(101,171,248, 0.2), 1.75em 1.75em 0 0em rgba(101,171,248, 0.2), 0em 2.5em 0 0em rgba(101,171,248, 0.2), -1.8em 1.8em 0 0em rgba(101,171,248, 0.5), -2.6em 0em 0 0em rgba(101,171,248, 0.7), -1.8em -1.8em 0 0em #65abf8;
  }
`;
/*eslint-enable*/

const Preloader = ({ display }) => {
  if (!display) return null;
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  );
};

export default Preloader;
