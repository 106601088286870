import common from "./common";

const theme = {
  ...common,
  colors: {
    activeButton: "#008CDC",
    activeButtonBackground: "white",
    analyticsHeader: "#8497B9",
    back: "#EEF4FF",
    border: "#47608E", 
    bottomMenu: "#C1D5F2",
    buttonIcons: "#EEF4FF",
    buttonIconsInactive: "#47608E",
    buttonInSelect: "#47608E",
    buttonText: "#47608E",
    campaignsTagBackground: "#FFB82E",
    campaignsTagText: "#524C4F",
    cardText: "#47608E",
    dashboardBigButtonHovered: "linear-gradient(180deg, #0059A9 0%, #009AD7 100%)",
    dashboardBigButtons: "linear-gradient(180deg, #009AD7 0%, #0059A9 100%)",
    dashboardCardText: "#47608E",
    dashboardText: "#47608E",
    dashedBoards: "#47608E",
    editorWrapperBackground: "#C1D5F2",
    filterBackground: "#E4ECFB",
    formBackground: "#C1D5F2",
    fullScreenBackground: "#E5ECFB",
    hoveredOption: "#D7E0F0",
    hoveredRow: "#8497B9",
    hoveredSelectRow: "#8497B9",
    hoveredSelectText: "#E5ECFB",
    hoveredText: "#E5ECFB",
    iconBack: "#DCE4F3",
    iconBackground: "white",
    iconBackground2: "#E5ECFB",
    iconNormal: "#B3BDD2",
    iconSelected: "#E6ECF6",
    iconSelectedBack: "#BFC9DC",
    iconsMenu: "#8497B9",
    ignoredStatus: "#524C4F",
    ignoredStatus2: "white",
    ignoredStatusBackground: "#FFB82E",
    ignoredStatusBackground2: "#EC3B3B",
    inactiveButton: "#B6C0D0",
    inactiveButton2: "#B6C0D0",
    inputBackground: "#DBE4F4",
    invoicesHeadRow: "#E4ECFB",
    legendText: "#47608E",
    lightH3: "#47608E",
    lineSelected: "#DBE4F4",
    logo: "linear-gradient(180deg, #009AD7 0%, #0059A9 100%)",
    menu: "#DBE4F4",
    menuDescription: "#47608E",
    openedRowBackground: "#EEF4FF",
    pageActiveBack: "#8FD77D",
    pageActiveBack2: "#4DAC6C",
    pageActiveText: "#32515E",
    pageActiveText2: "white",
    paginationBackground: "#C1D5F2",
    pdfContent: "#DDE5F5",
    placeholderText: "#8497B9",
    playerButtonBackground: "#DBE4F4",
    popup: "#DBE4F4",
    popupInputBackground: "white",
    popupText: "#47608E",
    prevAndNextBackground: "#C1D5F2",
    progressBarBackground: "white",
    removeButtonText: "#47608E",
    resizeIcon: "#6880A4",
    scrollMain: "#2F446D",
    scrollThumb: "#6880A4",
    searchInputBackground: "#EEF4FF",
    simpleInputText: "#47608E",
    subAuto: "#47608E",
    svgPath: "#465D89",
    tableGradient: "#DBE4F4",
    tableRow: "#E4ECFB",
    textEditorBackground: "#DBE4F4",
    textMenu: "#47608E",
    title: "#DBE4F4",
    videoPitchPopup: "#E4ECFB",
    wideInputBorderFocus: "#47608E"
  },
};

export default theme;
