import { createSlice } from "@reduxjs/toolkit";

export const KEY = "fontSize";

const fontSizeSlice = createSlice({
  name: KEY,
  initialState: { value: "14px" },
  reducers: {
    setFontSize: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const currentFontSize = state => state[KEY];
export const { setFontSize } = fontSizeSlice.actions;
export default fontSizeSlice.reducer;
