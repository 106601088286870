import { createSlice } from "@reduxjs/toolkit";

export const KEY = "conditions";

const paymentConditionsSlice = createSlice({
  name: KEY,
  initialState: {
    term: "month",
    subscriptionType: ""
  },
  reducers: {
    setConditionTerm: (state, action) => {
      state.term = action.payload;
    }
  },
});

export const { setConditionTerm } = paymentConditionsSlice.actions;
export const conditionTerm = (state) => state[KEY].term;
export const conditionSubscribeType = (state) => state[KEY].subscriptionType;
export default paymentConditionsSlice.reducer;
