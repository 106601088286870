import { createSlice } from "@reduxjs/toolkit";

export const KEY = "themeIsDark";

const darkThemeSlice = createSlice({
  name: KEY,
  initialState: { value: true },
  reducers: {
    changeDarkThemeStatus: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { changeDarkThemeStatus } = darkThemeSlice.actions;
export const selectThemeIsDark = (state) => state[KEY];

export default darkThemeSlice.reducer;

