import { createSlice } from "@reduxjs/toolkit";

export const KEY = "prevStep";

const prevStepSlice = createSlice({
  name: KEY,
  initialState: { prevStep: -1 },
  reducers: {
    setPrevStep: (state, action) => {
      state.prevStep = action.payload;
    }
  }
});

export const { setPrevStep } = prevStepSlice.actions;
export const getPrevStepSlice = state => state[KEY];
export default prevStepSlice.reducer;
