import { createSlice } from "@reduxjs/toolkit";

export const KEY = "italicActive";

const italicActiveSlice = createSlice({
  name: KEY,
  initialState: { value: false },
  reducers: {
    setItalicActive: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { setItalicActive } = italicActiveSlice.actions;
export const currentItalicActiveStatus = state => state[KEY];
export default italicActiveSlice.reducer;
