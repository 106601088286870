import { createSlice } from "@reduxjs/toolkit";

export const KEY = "dashboard";

const dashBoardSlice = createSlice({
  name: KEY,
  initialState: { value: {} },
  reducers: {
    changeDashboard: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { changeDashboard } = dashBoardSlice.actions;
export const getDashboard = state => state[KEY];
export default dashBoardSlice.reducer;
