import { createSlice } from "@reduxjs/toolkit";

export const KEY = "nextStepFunction";

const defaultFunction = async () => {};

const nextStepFunctionSlice = createSlice({
  name: KEY,
  initialState: { value: defaultFunction },
  reducers: {
    changeNextStepFunction: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { changeNextStepFunction } = nextStepFunctionSlice.actions;
export const getNextStepFunction = (state) => state[KEY];
export default nextStepFunctionSlice.reducer;
