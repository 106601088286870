import { configureStore } from "@reduxjs/toolkit";
import authUserInfo, * as user from "./redux/authUserInfo";
import boldActiveSlice, * as boldActive from "./redux/boldActiveSlice";
import campaignSlice, * as campaign from "./redux/campaignSlice";
import contactsFiltersSlice, * as contactsFilters from "./redux/contactsFiltersSlice";
import darkThemeSlice, * as darkTheme from "./redux/darkThemeSlice";
import dashBoardSlice, * as dashboard from "./redux/dashBoardSlice";
import editorFocusedSlice, * as editorFocused from "./redux/editorFocusedSlice";
import fontFamilySlice, * as fontFamily from "./redux/fontFamilySlice";
import fontSizeSlice, * as fontSize from "./redux/fontSizeSlice";
import hideMenuSlice, * as menuIsHidden from "./redux/hideMenuSlice";
import italicActiveSlice, * as italicActive from "./redux/italicActiveSlice";
import nextStepFunctionSlice, * as nextStepFunction from "./redux/nextStepFunctionSlice";
import paymentConditionsSlice, * as conditions from "./redux/paymentConditionsSlice";
import prevStepSlice, * as prevStep from "./redux/prevStepSlice";
import snackBarReducer, * as snackBar from "./redux/snackBar";
import textAlignSlice, * as textAlign from "./redux/textAlignSlice";
import typeOfListSlice, * as typeOfList from "./redux/typeOfListSlice";
import underlineActiveSlice, * as underlineActive from "./redux/underlineActiveSlice";

export default configureStore({
  reducer: {
    [darkTheme.KEY]: darkThemeSlice,
    [conditions.KEY]: paymentConditionsSlice,
    [user.KEY]: authUserInfo,
    [menuIsHidden.KEY]: hideMenuSlice,
    [editorFocused.KEY]: editorFocusedSlice,
    [boldActive.KEY]: boldActiveSlice,
    [italicActive.KEY]: italicActiveSlice,
    [underlineActive.KEY]: underlineActiveSlice,
    [fontSize.KEY]: fontSizeSlice,
    [fontFamily.KEY]: fontFamilySlice,
    [textAlign.KEY]: textAlignSlice,
    [typeOfList.KEY]: typeOfListSlice,
    [campaign.KEY]: campaignSlice,
    [nextStepFunction.KEY]: nextStepFunctionSlice,
    [prevStep.KEY]: prevStepSlice,
    [dashboard.KEY]: dashBoardSlice,
    [contactsFilters.KEY]: contactsFiltersSlice,
    [contactsFilters.NOT_CONTACT_KEY]: contactsFilters.notContactCampaignFilterReducer,
    [snackBar.KEY]: snackBarReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false, }),
});
