import { createSlice } from "@reduxjs/toolkit";

export const KEY = "fontFamily";

const fontFamilySlice = createSlice({
  name: KEY,
  initialState: { value: "Arial" },
  reducers: {
    setFontFamily: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const currentFontFamily = (state) => state[KEY];
export const { setFontFamily } = fontFamilySlice.actions;
export default fontFamilySlice.reducer;
