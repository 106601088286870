const appConfig = {
  apiUrl: process.env.REACT_APP_API_URL || "https://app.prai.us/api",
  apiWithCredentials: process.env.REACT_APP_API_WITH_CREDENTIALS === "1" || false,
  baseUrl: process.env.REACT_APP_BASE_URL || "https://app.prai.us",
  landingUrl: process.env.REACT_APP_LANDING_URL || "https://prai.co",
  reCaptchaKey: process.env.REACT_APP_SECRET_RECAPTCHA_KEY || "",
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "",
};

export default appConfig;
