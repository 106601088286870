import { createSlice } from "@reduxjs/toolkit";

export const KEY = "editorFocused";

const editorFocusedSlice = createSlice({
  name: KEY,
  initialState: { value: false },
  reducers: {
    setEditorFocused: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { setEditorFocused } = editorFocusedSlice.actions;
export const editorFocusedStatus = state => state[KEY];
export default editorFocusedSlice.reducer;
