import { createSlice } from "@reduxjs/toolkit";

export const KEY = "menuIsHidden";

const hideMenuSlice = createSlice({
  name: KEY,
  initialState: { value: window.matchMedia('(max-width: 768px)').matches ? true : false },
  reducers: {
    setMenuStatus: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { setMenuStatus } = hideMenuSlice.actions;
export const menuIsHidden = (state) => state[KEY];

export default hideMenuSlice.reducer;
