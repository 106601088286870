import { createSlice } from "@reduxjs/toolkit";

export const KEY = "boldActive";

const boldActiveSlice = createSlice({
  name: KEY,
  initialState: { value: false },
  reducers: {
    setBoldActive: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { setBoldActive } = boldActiveSlice.actions;
export const currentBoldActiveStatus = state => state[KEY];
export default boldActiveSlice.reducer;
