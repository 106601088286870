import arial from "../fonts/Arial/arial.woff";
import arialbd from "../fonts/Arial/arialbd.woff";
import arialbi from "../fonts/Arial/arialbi.woff";
import ariali from "../fonts/Arial/ariali.woff";
import georgia from "../fonts/Georgia/georgia.woff";
import georgiaBold from "../fonts/Georgia/georgia_bold.woff";
import georgiaItalic from "../fonts/Georgia/georgia_italic.woff";
import georgiaBoldItalic from "../fonts/Georgia/georgia_bold_italic.woff";
import helvetica from "../fonts/Helvetica/HelveticaRegular.woff";
import helveticaBold from "../fonts/Helvetica/HelveticaBold.woff";
import helveticaBoldItalic from "../fonts/Helvetica/HelveticaBoldItalic.woff";
import helveticaItalic from "../fonts/Helvetica/HelveticaItalic.woff";
import tahoma from "../fonts/Tahoma/tahoma.woff";
import tahomaBI from "../fonts/Tahoma/tahoma-bold-italic.woff";
import tahomaI from "../fonts/Tahoma/tahoma-italic.woff";
import tahomaB from "../fonts/Tahoma/tahomabd.woff";
import times from "../fonts/Times New Roman/times.woff";
import timesB from "../fonts/Times New Roman/times_bold.woff";
import timesI from "../fonts/Times New Roman/times_italic.woff";
import timesBI from "../fonts/Times New Roman/timesb_bold_italic.woff";
import trebuchet from "../fonts/Trebuchet MS/trebuc.woff";
import trebuchetB from "../fonts/Trebuchet MS/trebuc bold.woff";
import trebuchetI from "../fonts/Trebuchet MS/trebuc_italic.woff";
import trebuchetBI from "../fonts/Trebuchet MS/trebuc bold italic.woff";
import verdana from "../fonts/Verdana/verdana.woff";
import verdanaB from "../fonts/Verdana/verdana_bold.woff";
import verdanaI from "../fonts/Verdana/verdana_italic.woff";
import verdanaBI from "../fonts/Verdana/verdana_bold_italic.woff";
import React from "react";
import { useSelector } from "react-redux";
import { createGlobalStyle } from "styled-components";
import { selectThemeIsDark } from "../redux/darkThemeSlice";
import darkThemes from "../themes/dark";
import lightThemes from "../themes/light";

const GlobalStyle = createGlobalStyle`
.grecaptcha-badge {
  opacity: 0;
  pointer-events: none;
};

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    line-height: 1.5;
    background-color: ${({ theme }) => theme.colors.back};
  }

  p, button, div, label, h1, h2, h3, h4, h5, input, td {
    font-family: 'Poppins', sans-serif;
  } 

  * {
    box-sizing: border-box;

    ::-webkit-scrollbar {
      width: 10px;
      background-color: ${({ theme }) => theme.colors.scrollMain};
      border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: ${({ theme }) => theme.colors.scrollThumb};
    }
  }

  h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0
  }
};

.DraftEditor-editorContainer, .public-DraftEditor-content {
  height: 100%;
};

.DraftEditor-editorContainer {
  * {
    word-break: break-word;
  }
};

@media(max-width: 374px) {
  html {
    font-size: 13px
  }
};

@media(min-width: 375px) and (max-width: 768px) {
  html {
    font-size: 16px
  }
};

@media(min-width: 768px) and (max-width: 1150px) {
  html {
    font-size: 10px
  }
};

@media(min-width: 1150px) and (max-width: 1250px) {
  html {
    font-size: 11px
  }
}
@media(min-width: 1250px) and (max-width: 1400px) {
  html {
    font-size: 12px;
  };
};

@media(min-width: 1400px) and (max-width: 1900px) {
  html {
    font-size: 14px;
  };
};

@media(min-width: 1900px) {
  html {
    font-size: 16px;
  };
};

@font-face {
  font-family: "Arial";
  src: url(${arial}) format("woff"),
  url(${arialbd}) format("woff"),
  url(${arialbi}) format("woff"),
  url(${ariali}) format("woff")
};

@font-face {
  font-family: "Helvetica";
  src: url(${helvetica}) format("woff"),
  url(${helveticaBold}) format("woff"),
  url(${helveticaBoldItalic}) format("woff"),
  url(${helveticaItalic}) format("woff")
};

@font-face {
  font-family: "Tahoma";
  src: url(${tahoma}) format("woff"),
  url(${tahomaB}) format("woff"),
  url(${tahomaBI}) format("woff"),
  url(${tahomaI}) format("woff")
};

@font-face {
  font-family: "Georgia";
  src: url(${georgia}) format("woff"),
  url(${georgiaBold}) format("woff"),
  url(${georgiaBoldItalic}) format("woff"),
  url(${georgiaItalic}) format("woff")
};


@font-face {
  font-family: "Times New Roman";
  src: url(${times}) format("woff"),
  url(${timesB}) format("woff"),
  url(${timesI}) format("woff"),
  url(${timesBI}) format("woff")
};

@font-face {
  font-family: "Trebuchet MS";
  src: url(${trebuchet}) format("woff"),
  url(${trebuchetB}) format("woff"),
  url(${trebuchetI}) format("woff"),
  url(${trebuchetBI}) format("woff")
};

@font-face {
  font-family: "Verdana";
  src: url(${verdana}) format("woff"),
  url(${verdanaB}) format("woff"),
  url(${verdanaI}) format("woff"),
  url(${verdanaBI}) format("woff")
};

@font-face {
  font-family: "Arial Black";
  src: url(${verdanaB}) format("woff"),
  url(${verdanaBI}) format("woff")
};
`;

const GlobalStyleWithParams = () => {
  const themeIsDark = useSelector(selectThemeIsDark).value;
  const theme = themeIsDark ? darkThemes : lightThemes;

  return <GlobalStyle theme={theme}/>;
};

export default GlobalStyleWithParams;
