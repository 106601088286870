import { createSlice } from "@reduxjs/toolkit";

export const KEY = "underlinedActive";

const underlineActiveSlice = createSlice({
  name: KEY,
  initialState: { value: false },
  reducers: {
    setUnderlinedActive: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { setUnderlinedActive } = underlineActiveSlice.actions;
export const currentUnderlineActiveStatus = state => state[KEY];
export default underlineActiveSlice.reducer;
