import { createSlice } from "@reduxjs/toolkit";

export const KEY = "textAlign";

const textAlignSlice = createSlice({
  name: KEY, 
  initialState: { value: "left" },
  reducers: {
    setTextAlign: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const currentTextAlign = (state) => state[KEY];
export const { setTextAlign } = textAlignSlice.actions;
export default textAlignSlice.reducer;
