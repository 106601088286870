import common from "./common";

const theme = {
  ...common,
  colors: {
    activeButton: "linear-gradient(180deg, #015AAA 0%, #019AD7 100%)",
    activeButtonBackground: "#2E4264",
    analyticsHeader: "white",
    back: "#1D2A44",
    border: "#2D3E5C",
    bottomMenu: "#1C1C1C",
    buttonIcons: "#B6C0D0",
    buttonIconsInactive: "#B6C0D0",
    buttonInSelect: "#2D3E5C",
    buttonText: "#7F8CAF",
    campaignsTagBackground: "#524C4F",
    campaignsTagText: "#E48A23",
    cardText: "#D2D8E4",
    dashboardBigButtonHovered: "#465D89",
    dashboardBigButtons: "#2D3D5C",
    dashboardCardText: "#8497B9",
    dashboardText: "#A5B0C1",
    dashedBoards: "#b6c0d0",
    editorWrapperBackground: "#3A537E",
    filterBackground: "#2C3F61",
    formBackground: "#465D89",
    fullScreenBackground: "#1D2A44",
    hoveredOption: "#2D3D5C",
    hoveredRow: "#2D3D5C",
    hoveredSelectRow: "#2D3D5C",
    hoveredSelectText: "#B6C0D0",
    hoveredText: "#D2D8E4",
    iconBack: "#3A4C6B",
    iconBackground: "#3A4C6B",
    iconBackground2: "#2D3E5C",
    iconSelectedBack: "#3A4C6B",
    iconsMenu: "#B6C0D0",
    ignoredStatus: "#EC3B3B",
    ignoredStatus2: "#EC3B3B",
    ignoredStatusBackground: "#5E3232",
    ignoredStatusBackground2: "#5E3232",
    inactiveButton: "#394358",
    inactiveButton2: "#2D3D5C",
    inputBackground: "#2C3F61",
    invoicesHeadRow: "#465D89",
    legendText: "#88A0C9",
    lightH3: "#EFEFEF",
    lineSelected: "#26344F",
    logo: "linear-gradient(180deg, #FFFFFF 0%, rgba(43, 60, 98, 0.99) 100%)",
    menu: "#1A253D",
    menuDescription: "white",
    openedRowBackground: "#3E557C",
    pageActiveBack: "#32515E",
    pageActiveBack2: "#32515E",
    pageActiveText: "#4DAC6C",
    pageActiveText2: "#4DAC6C",
    paginationBackground: "#2D3D5C",
    pdfContent: "#2D3E5C",
    placeholderText: "#B6C0D0",
    playerButtonBackground: "#2F446D",
    popup: "#3C5075",
    popupInputBackground: "#8497B9",
    popupText: "#E5ECFB",
    prevAndNextBackground: "#B6C0D0",
    progressBarBackground: "#B6C0D0",
    removeButtonText: "#C4C4C4",
    resizeIcon: "#B6C0D0",
    scrollMain: "#2F446D",
    scrollThumb: "#556A90",
    searchInputBackground: "#2D3E5C",
    simpleInputText: "#465D89",
    subAuto: "auto",
    svgPath: "#B6C0D0",
    tableGradient: "linear-gradient(180deg, #24324D 0%, #293753 100%)",
    tableRow: "#2D3D5C",
    textEditorBackground: "#2D3E5C",
    textMenu: "#B6C0D0",
    title: "#2D3D5C",
    videoPitchPopup: "#3C5075",
    wideInputBorderFocus: "#2D89DD"
  },
};

export default theme;
