import { createSlice } from "@reduxjs/toolkit";

export const KEY = "contactsFilter";
export const NOT_CONTACT_KEY = "notContactCampaignFilter";

const contactsFilterDefaultValue = [
  { name: "First Name", filterName: "firstName", values: [] },
  { name: "Last Name", filterName: "lastName", values: [] },
  { name: "Subject", filterName: "subjects", values: [] },
  { name: "Company type", filterName: "companyType", values: [] },
  { name: "Position", filterName: "position", values: [] },
  { name: "City", filterName: "city", values: [] },
  { name: "State", filterName: "state", values: [] },
  { name: "Country", filterName: "country", values: [] },
  { name: "Contact list", filterName: "contactList", values: [] },
  { name: "Contact Language", filterName: "workingLanguages", values: [] },
  { name: "Campaigns", filterName: "campaignList", values: [] },
];

const contactsFiltersSlice = createSlice({
  name: KEY,
  initialState: { value: contactsFilterDefaultValue, },
  reducers: {
    changeContactsFiltersSlice: (state, action) => {
      state.value = action.payload;
    },
    clearContactsFiltersSlice: (state, action) => {
      state.value = contactsFilterDefaultValue;
    },
  },
});

const notContactCampaignFilter = createSlice({
  name: NOT_CONTACT_KEY,
  initialState: { ids: [] },
  reducers: {
    setNotContactCampaignFilter: (state, action) => {
      state.ids = action.payload;
    },
    clearNotContactCampaignFilter: (state, action) => {
      state.ids = contactsFilterDefaultValue;
    },
  },
});

export const notContactCampaignFilterReducer = notContactCampaignFilter.reducer;
export const getNotContactCampaignFilter = (state) => state[NOT_CONTACT_KEY];
export const { 
  setNotContactCampaignFilter, 
  clearNotContactCampaignFilter 
} = notContactCampaignFilter.actions;

export const { changeContactsFiltersSlice, clearContactsFiltersSlice } =
  contactsFiltersSlice.actions;
export const getContactsFilters = (state) => state[KEY];

export default contactsFiltersSlice.reducer;
