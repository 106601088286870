import { createSlice } from "@reduxjs/toolkit";

export const KEY = "campaign";

const campaignSlice = createSlice({
  name: KEY,
  initialState: { value: {} },
  reducers: {
    changeCampaign: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { changeCampaign } = campaignSlice.actions;
export const getCampaign = state => state[KEY];
export default campaignSlice.reducer;
