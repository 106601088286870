import { Suspense, lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import GlobalStyleWithParams from "./components/GlobalStyleWithParams";
import Preloader from "./components/PreLoader";
import { getAuthUser } from "./redux/authUserInfo";
import { selectThemeIsDark, } from "./redux/darkThemeSlice";
import darkThemes from "./themes/dark";
import lightThemes from "./themes/light";

const PrivateRouter = lazy(() => import("./routes/PrivateRouter"));

const Login = lazy(() => import("./pages/Login"));
const Registration = lazy(() => import("./pages/Registration"));
const PasswordReset = lazy(() => import("./pages/PasswordReset"));
const PasswordRecover = lazy(() => import("./pages/PasswordRecover"));
const TermsAndConditions = lazy(() => import("./pages/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Verification = lazy(() => import("./pages/Verification"));
const CreateProfile = lazy(() => import("./pages/CreateProfile"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));

const AdminLoginPage = lazy(() => import("./pages/AdminPages/AdminLoginPage"));

function App () {
  const themeIsDark = useSelector(selectThemeIsDark).value;
  const theme = themeIsDark ? darkThemes : lightThemes;
  const authUser = useSelector(getAuthUser);

  useEffect(() => {
    const hideSecondDiv = () => {
      const secondDiv = document.querySelector('.INDmenuFooter > div:nth-child(2)');
      if (secondDiv) {
        secondDiv.style.display = 'none';
      }
    };

    const observer = new MutationObserver((mutationsList, observer) => {
      for(let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          hideSecondDiv();
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();  // очистка при размонтировании
  }, []);

  return (
    <div className="App">
      {authUser?.user?.role !== "admin" && <GlobalStyleWithParams />}

      <ThemeProvider theme={theme}>
        <Suspense fallback={<Preloader display={true} />}>
          <BrowserRouter>
            <Switch>
              <Route path="/" component={Login} exact />
              <Route path="/registration" exact component={Registration} />
              <Route path="/restore-password" exact component={PasswordReset} />
              <Route path="/recover-password" exact component={PasswordRecover} />
              <Route path="/terms-of-service" component={TermsAndConditions} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/verification" component={Verification} />
              <Route path="/google" component={CreateProfile} />
              <Route path="/facebook" component={CreateProfile} />
              <Route path="/apple" component={CreateProfile} />
              <Route path="/not-found" component={PageNotFound} />
              <Route path="/admin/login" exact component={AdminLoginPage} />

              <Route path="/*" exact component={PrivateRouter} />
            </Switch>
          </BrowserRouter>
        </Suspense>
      </ThemeProvider>
    </div>
  );
}

export default App;
