const common = {
  generalColor: {
    activeStatusBackground: "#FFB82E",
    biz: "#009BD8",
    black: "#000000",
    blue: "#009ad7",
    buttonGradient: "linear-gradient(180deg, #015AAA 0%, #019AD7 100%);",
    buttonGradient2: "linear-gradient(180deg, #0059A9 0%, #009AD7 100%)",
    buttonGradient3: "linear-gradient(180deg, #009AD7 0%, #0059A9 100%)",
    buttonGradient4: "linear-gradient(180deg, #0358A8 0%, #009BD8 100%)",
    buttonGradient5: "linear-gradient(180deg, #A20E42 0%, #DF3E76 100%)",
    buttonGradient6: "linear-gradient(128.16deg, #B8275C -7.12%, #22BDFF 55.37%);",
    buttonGradient7: "linear-gradient(90deg, #9E2C66 -9.5%, #009BD8 110.5%);",
    buttonsWrapperGradient:
    "linear-gradient(93.34deg, #9E2C66 0%, #009BD8 100%)",
    buttonsWrapperGradient2:
    "linear-gradient(90deg, #009BD8 -9.5%, #9E2C66 110.5%)",
    crossedPrice: "#EB0505",
    darkBack: "#232324",
    darkButton: "#141415",
    dateInfo: "#C1C1C1",
    editorTextColored: "#EAEAEA",
    error: "#EE6B75",
    filledButton: "#008CDC",
    ignoredStatusBackground2: "#DE6363",
    miniPopupHeadBackground: "#2D4260",
    notificationBack: "#EB5757",
    openedRowBackground2: "#293852",
    percentButtonText: "#0F8FCC",
    placeHolder: "#D0D0D0",
    popupBackground: "rgba(14, 14, 14, 0.8)",
    popupButtonBackground: "#2D89DD",
    popupInputText: "#47608E",
    price: "#00CA30",
    shareButtonText: "#7F8CAF",
    stepText: "#465D89",
    success: "#8FD77D",
    tagBackground: "#7F8CAF",
    textMenu: "#B6C0D0",
    textWithLabel: "#929292",
    textareaPlaceholder: "#5170A5",
    tileDarkText: "#66789C",
    toggleBackground: "#1D2A44",
    toggleText: "#0592BC",
    tooltipText: "#DFE6F3",
    unfinishedStatus: "#524C4F",
    white: "#FFFFFF",
  },
};

export default common;
