import { createSlice, current } from "@reduxjs/toolkit";

export const KEY = "snackBar";

const defaultData = {
  duration: 5000,
  horizontal: "right",
  message: "",
  open: false,
  type: "info",
  vertical: "top",
};

const snackBar = createSlice({
  name: KEY,
  initialState: defaultData,
  reducers: {
    setSnackBar: (state, action) => {
      const newState = { 
        ...current(state),
        ...action.payload
      };

      return newState;
    },
    setDefault: () => {
      return defaultData;
    }
  }
});

export const { setSnackBar, setDefault } = snackBar.actions;
export const getSnackBar = state => state[KEY];
export default snackBar.reducer;
